import { type MiniCartProps } from './minicart';
import { SAP_COMMERCE_URL } from './minicart.constants';
import { useCallback, useEffect, useState } from 'react';
import { dispatchErrorEvent } from './minicart.events';
import { MiniCart } from './minicart.types';

const isValidMiniCart = (cart: unknown): cart is MiniCart => {
  if (!!cart && typeof cart === 'object') {
    const requiredKeys = ['entries'];

    return requiredKeys.every((key) => key in cart);
  }

  return false;
};

const parseMiniCart = (cart: unknown): MiniCart | undefined => {
  // Handle valid cart without entries
  // if (!!cart && typeof cart === 'object' && !('entries' in cart)) return { ...cart, entries: [] };

  // // Add cacheCode to valid cart with entries
  // if (!!cart && typeof cart === 'object' && 'entries' in cart && Array.isArray(cart.entries)) {

  if (isValidMiniCart(cart)) {
    return {
      ...cart,
      entries: cart?.entries?.map((entry) => ({
        ...entry,
        cacheCode: Math.floor(Date.now()) + entry.entryNumber.toString(),
      })),
    };
  }
};

const fetchCart = async ({
  store,
  locale,
  token,
}: {
  store: MiniCartProps['store'];
  locale: string;
  token: MiniCartProps['token'];
}): Promise<MiniCart | undefined> => {
  const baseSite = `${store}Site`;

  try {
    const response = await fetch(
      `${SAP_COMMERCE_URL}/${baseSite}/users/current/carts/current?fields=MINICART&lang=${locale.substring(
        0,
        2,
      )}`,
      {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      },
    );
    const result = await response.json();

    if (result.errors) {
      throw result.errors;
    }

    const miniCart: MiniCart | undefined = parseMiniCart(result);

    if (typeof miniCart === 'undefined') {
      throw new Error('invalid cart');
    }

    return miniCart;
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
    throw error;
  }
};

export const removeProductFromCart = async ({
  token,
  store = 'sat',
  entryNumber = 0,
}: {
  token?: string | undefined;
  store?: string;
  entryNumber: number;
}) => {
  try {
    const cartUrl = `${SAP_COMMERCE_URL}/${store}Site/users/current/carts/current/entries/${entryNumber}`;

    const response = await fetch(cartUrl, {
      method: 'DELETE',
      headers: { Authorization: `bearer ${token}` },
    });

    if (response.status !== 200) throw new Error('something went wrong');
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const useGetCart = ({
  token,
  locale = 'de-DE',
  store = 'sat',
  isNamedUser = false,
}: {
  token?: string | undefined;
  locale?: string;
  store?: string;
  isNamedUser?: boolean;
}) => {
  const [cart, setCart] = useState<MiniCart | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const removeProduct = (entryNumber: number) => {
    setIsLoading(true);

    removeProductFromCart({ token, store, entryNumber })
      .then(() => getCart())
      .catch(() => setIsError(true));
  };

  const reload = () => {
    setIsSuccess(false);

    getCart().catch(() => setIsError(true));
  };

  useEffect(() => {
    if (isSuccess && isNamedUser) {
      const isCalculated = cart?.calculated;

      if (!isCalculated) {
        const timeout = setTimeout(() => {
          reload();
        }, 8000);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [isSuccess]);

  const getCart = useCallback(async () => {
    try {
      const cartData = await fetchCart({ store, locale, token });

      setCart(cartData ?? null);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      setCart(null);
      setIsError(true);
      dispatchErrorEvent(error);
    }
  }, [locale, store, token]);

  useEffect(() => {
    setIsLoading(true);

    getCart();
  }, [locale, store, token, getCart]);

  return { data: cart, isLoading, isError, removeProduct, reload, isSuccess };
};

// CONSTANTS
const MINICART_EVENT = {
  ERROR: 'stoeMiniCartError',
};
const ADDTOCART_EVENT = {
  ERROR: 'stoeaddToCartError',
  SUCCESS: 'stoeAddToCartSuccess',
};

const dispatchErrorEvent = (errorLog: unknown) => {
  const stoeErrorEvent = new CustomEvent(MINICART_EVENT.ERROR, { detail: errorLog });

  window.dispatchEvent(stoeErrorEvent);
};

export { MINICART_EVENT, ADDTOCART_EVENT, dispatchErrorEvent };

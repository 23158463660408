import { useEffect, useMemo, useRef, useState } from 'react';
import compStyle from './minicart.css?inline';

import { BUTTON_CLASS, STYLE_TRIGGER, STYLE_TRIGGER_COUNT } from './minicart.style';
import { useGetCart } from './minicart.query';
import { I18nTranslations, MiniCartProps } from './minicart.types';
import { closeOnOutOfBoundaryClick, getUserTypeFromToken } from './minicart.helper';
import { ADDTOCART_EVENT } from './minicart.events';
import { CART_URL_SAT, CART_URL_SDI, i18nTranslationsDefault } from './minicart.constants';

// @root points to root of parent dir. Use it like this:
// import globalStyle from '@root/src/app/globals.css?inline'

// styles need to be exported to entrypoint like this:
const stylesList = [
  { id: 'CompStyles', style: compStyle },
  // { id: 'GlobalStyles', style: globalStyle },
];

// this props also get exported to entrypoint

function MiniCart({ token, store, locale, i18n }: MiniCartProps) {
  const lowerCaseStore = store?.toLocaleLowerCase();
  if (!token) console.error('Missing AccessToken');
  const isDefaultLanguage = locale === 'de-DE' || locale === 'en-US';
  const hasLocaleNoI18n = !!locale && !i18n;
  if (!isDefaultLanguage && hasLocaleNoI18n)
    console.error('Please provide a valid i18n translations for ' + locale);
  /**
   * 1. Implement shadcn Popover (/)
   * - On mount check for cart (/)
   * 2. Trigger
   * - Show amount of items in cart (/)
   * - Disable for < 1 product in cart
   * - Disable for invalid cart (/)
   * 3. Content
   * - Disable for invalid cart (/)
   * - Disable for < 1 product in cart
   * - show products
   * - Hide price for unnamed users
   * - Enable delete
   * - Add "to checkout" button
   * 4. Add overlay on remove
   * 5. Add Event Listener
   * 6. I18n
   */

  const isNamedUser = getUserTypeFromToken(token);

  const {
    data: cartData,
    isLoading,
    isError,
    removeProduct,
    reload,
  } = useGetCart({ token, store: lowerCaseStore, locale: locale, isNamedUser });

  const [isOpen, setIsOpen] = useState(false);
  const cartCount = !token ? 0 : cartData?.entries?.length ?? 0;
  const cartLinkUrl = lowerCaseStore?.toUpperCase() === 'SAT' ? CART_URL_SAT : CART_URL_SDI;

  const i18nTranslations = {
    ...i18nTranslationsDefault[locale === 'de-DE' ? 'de-DE' : 'en-US'],
    ...(i18n ? (JSON.parse(i18n) as I18nTranslations) : {}),
  };

  useEffect(() => {
    const refetchOnAddToCartEvent = () => {
      reload();
    };

    window.addEventListener(ADDTOCART_EVENT.SUCCESS, refetchOnAddToCartEvent);

    return () => window.removeEventListener(ADDTOCART_EVENT.SUCCESS, refetchOnAddToCartEvent);
  }, [reload]);

  useEffect(() => {
    if (isError) setIsOpen(false);
  }, [isError]);

  const createConfigUrl = (
    locale: string,
    shop: string,
    productSelectionHash: string,
    configuratorHash: string,
  ) => {
    return `https://configurator.${
      shop === 'sat' ? 'stoeber.de' : 'stober.com'
    }/${locale}/product-configuration?shop=${shop}&productSelectionStrip=${productSelectionHash}&configurationStrip=${configuratorHash}`;
  };

  useEffect(() => {
    /** TODOS
     * - close popover on out of boundary click
     * - Prevent trigger on popover trigger click
     */
    if (isOpen && cartCount > 0) {
      const closeOnOutOfBoundaryClickEvent = (event: MouseEvent) => {
        closeOnOutOfBoundaryClick(event, () => setIsOpen(false));
      };

      window.addEventListener('click', closeOnOutOfBoundaryClickEvent);

      return () => {
        window.removeEventListener('click', closeOnOutOfBoundaryClickEvent);
      };
    }
  }, [isOpen]);

  return (
    <>
      <div data-testid="stoe-mini-cart" className="relative font-cb">
        <button
          key={cartCount}
          onClick={() => setIsOpen((prev) => !prev)}
          disabled={cartCount < 1 || !token}
          data-testid="minicart-trigger"
          {...(cartCount < 1 || isError ? { 'data-state': 'disabled' } : {})}
          className={` ${STYLE_TRIGGER.default} ${STYLE_TRIGGER.disabled} ${
            !isOpen && cartCount > 0 && STYLE_TRIGGER.hover
          } ${isOpen && cartCount > 0 && STYLE_TRIGGER.active}`}
        >
          <span data-testid="minicart-trigger-label" className=" ">
            {i18nTranslations.trigger.label}
          </span>

          <span
            data-testid="minicart-trigger-count-wrapper"
            className={`${STYLE_TRIGGER_COUNT.default} ${
              cartCount < 1 && STYLE_TRIGGER_COUNT.disabled
            }`}
          >
            <span className="sr-only">{i18nTranslations.trigger.count}</span>
            <span data-testid="minicart-trigger-count">{cartCount}</span>
          </span>
        </button>
        {/* {cartCount > 0 && isOpen && ( */}{' '}
        {isOpen && cartCount > 0 && (
          <div
            data-testid="minicart-content"
            aria-label="Minicart"
            className={`absolute right-0 border border-solid border-stoe-gray-dark bg-white  max-sm:fixed   max-sm:bottom-0 max-sm:top-0 max-sm:flex  max-sm:flex-col  `}
            role={'region'}
          >
            <div className=" hidden justify-end p-2 max-sm:flex ">
              <button onClick={() => setIsOpen(false)}>
                <svg
                  width="73"
                  height="73"
                  viewBox="-1 -73 73 73"
                  className="h-8 w-8 fill-stoe-gray-dark hover:fill-stoe-gray-light hover:font-bold"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M71.5680-69.4080L38.0880-36.0000L71.5680-2.5920L69.4080-0.4320L36.0000-33.9120L2.5920-0.4320L0.4320-2.5920L33.9120-36.0000L0.4320-69.4080L2.5920-71.5680L36.0000-38.0880L69.4080-71.5680L71.5680-69.4080Z" />
                </svg>
                <span className="sr-only">{i18nTranslations.content.close}</span>
              </button>
            </div>
            <div
              data-testid="product-list"
              className="relative w-80 overflow-x-clip overflow-y-scroll bg-white max-sm:w-screen max-sm:flex-grow sm:max-h-96"
            >
              {isLoading && (
                <div
                  data-testid="list-overlay"
                  className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-white opacity-80"
                >
                  <Spinner />
                </div>
              )}
              {cartData?.entries.map((product) => (
                <ProductCard
                  name={product.stoeProductName}
                  key={product.cacheCode}
                  imageURL={product.imageURL}
                  calculated={cartData.calculated ?? false}
                  quantity={product.quantity}
                  group={product.stoeProductGroup}
                  onRemove={() => removeProduct(product.entryNumber)}
                  isNamedUser={isNamedUser}
                  i18nTranslations={i18nTranslations}
                  configUrl={createConfigUrl(
                    locale ?? 'de-DE',
                    lowerCaseStore ?? 'sat',
                    product.stoeConfigurationHash,
                    product.stoeProductSelectionHash,
                  )}
                  price={
                    product.basePrice && product.totalPrice
                      ? {
                          basePrice: product.basePrice?.formattedValue,
                          totalPrice: product.totalPrice?.formattedValue,
                        }
                      : undefined
                  }
                />
              ))}
            </div>
            <div data-testid="checkout-cta" className="flex h-auto w-full  ">
              <a
                data-testid="cart-url"
                target={'_blank'}
                href={cartLinkUrl}
                className={`${BUTTON_CLASS.primary} w-full text-center font-cb-bold font-bold`}
              >
                {i18nTranslations.content.toCart}
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const ProductCard = ({
  quantity,
  group,
  imageURL,
  calculated,
  onRemove,
  isNamedUser,
  i18nTranslations,
  name,
  configUrl,
  price,
}: {
  quantity: number;
  calculated: boolean;
  group: string;
  imageURL: string;
  onRemove: () => void;
  isNamedUser: boolean;
  i18nTranslations: I18nTranslations[string];
  name: string;
  configUrl: string;
  price?: { basePrice: string; totalPrice: string };
}) => {
  /**
   * Show Image
   * Show Title + Link
   * Show Price
   * Show Remove
   * Show Amount
   */

  return (
    <>
      <div className="relative flex w-full flex-row-reverse px-1">
        <a
          data-testid="config-url"
          href={configUrl}
          target={'_blank'}
          className=" w-2/3 p-2 font-bold text-stoe-blue hover:underline"
        >
          {group}
        </a>
      </div>
      <div
        className={
          'relative w-full border border-b border-white border-b-stoe-gray-light px-1   hover:bg-stoe-gray-light'
        }
        data-testid="product-card"
      >
        <div data-testid="product-card-wrapper">
          <div className="flex flex-row  ">
            <div className="min-w-7 basis-1/3 p-1 text-xs">
              <img src={imageURL} alt={`Image ${group}`} />
            </div>
            <div className="flex basis-2/3 flex-col p-2 text-base">
              <div className=" mb-2 flex flex-col">
                <span className="  text-sm text-stoe-gray-dark">{name}</span>
              </div>

              {isNamedUser && (
                <div className="mb-2 flex flex-col text-right" data-testid="price-container">
                  {!calculated && <Spinner />}
                  {calculated && price?.basePrice && price?.totalPrice && (
                    <>
                      <span data-testid="price">{price?.totalPrice}</span>
                      {quantity > 1 && (
                        <span data-testid="unit-price" className="text-xs text-stoe-gray-dark">
                          {i18nTranslations.content.unitPrice} {price?.basePrice}
                        </span>
                      )}
                    </>
                  )}
                </div>
              )}
              <div className="text-exo flex flex-row justify-between text-sm ">
                <div>x {quantity}</div>
                <button
                  data-testid="product-card-remove-btn"
                  className={` m-0  flex items-center  justify-center    p-0 text-2xl font-bold leading-none hover:text-white`}
                  onClick={() => onRemove()}
                  title={i18nTranslations.content.removeProduct}
                >
                  <i className="stoeber-icon  stoeber-icon-remove m-0 p-0 leading-none" />
                  <svg
                    className="m-0 h-5 w-5 fill-stoe-gray-dark p-0 leading-none hover:fill-white "
                    width="53"
                    height="62"
                    viewBox="8 -67 53 62"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M18.0000-57.0240L50.9760-57.0240L50.9760-9.0000L18.0000-9.0000L18.0000-57.0240ZM54.0000-5.9760L54.0000-59.9760L14.9760-59.9760L14.9760-5.9760L54.0000-5.9760ZM30.0240-63.0000L39.0240-63.0000L39.0240-59.9760L30.0240-59.9760L30.0240-63.0000ZM41.9760-57.0240L41.9760-66.0240L27.0000-66.0240L27.0000-57.0240L41.9760-57.0240ZM59.9760-57.0240L59.9760-59.9760L9.0000-59.9760L9.0000-57.0240L59.9760-57.0240ZM27.0000-14.9760L27.0000-50.9760L23.9760-50.9760L23.9760-14.9760L27.0000-14.9760ZM36.0000-14.9760L36.0000-50.9760L32.9760-50.9760L32.9760-14.9760L36.0000-14.9760ZM45.0000-14.9760L45.0000-50.9760L41.9760-50.9760L41.9760-14.9760L45.0000-14.9760Z" />
                  </svg>
                  <span className="sr-only">{i18nTranslations.content.removeProduct}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Spinner = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="inline h-6 w-6 animate-spin fill-stoe-blue text-gray-200 dark:text-gray-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">{'Loading...'}</span>
    </div>
  );
};

export { stylesList, ProductCard };
export type { MiniCartProps };
export default MiniCart;

const STYLE_TRIGGER = {
  default: 'relative flex h-[50px] items-center justify-center px-5 pr-3 text-stoe-gray-dark ',
  disabled:
    'disabled:p-2 disabled:bg-white   disabled:pointer-events-none  disabled:text-stoe-gray',
  hover: 'hover:bg-stoe-gray-light hover:text-white ',
  active: ' bg-stoe-gray-dark  text-white ',
};

const STYLE_TRIGGER_COUNT = {
  default:
    'ml-1 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-stoe-blue text-xs font-bold text-white',
  disabled: ' bg-stoe-gray-light',
};

export const BUTTON_CLASS = {
  default:
    'p-2 font-bold bg-white border-2 border-stoe-gray-dark text-stoe-gray-dark hover:bg-stoe-gray-dark hover:text-white',
  primary:
    'p-2 font-bold bg-stoe-blue  text-white hover:bg-stoe-gray-dark hover:text-white hover:cursor-pointer',
  disabled:
    'p-2 font-bold bg-white border-2 pointer-events-none cursor-not-allowed border-stoe-gray-light bg-stoe-gray-light text-white',
};

export { STYLE_TRIGGER, STYLE_TRIGGER_COUNT };

import { RefObject } from 'react';

export interface DefaultJWT extends Record<string, unknown> {
  name?: string | null;
  email?: string | null;
  picture?: string | null;
  sub?: string;
}

export interface JWT extends Record<string, unknown>, DefaultJWT {
  userType: string;
}

export const parseJWT = (token: string): JWT | undefined => {
  if (!token) return;

  const base64Url = token.split('.')[1] ?? '';
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getUserTypeFromToken = (token: string | undefined) => {
  let isNamedUser = false;

  if (token) {
    const parsedToken = parseJWT(token);

    isNamedUser = parsedToken?.userType === 'named_user';
  }

  return isNamedUser;
};

const eventTargetIsHTMLElement = (eventTarget: unknown): eventTarget is HTMLElement =>
  eventTarget instanceof HTMLElement;

export const closeOnOutOfBoundaryClick = (event: MouseEvent, callback: () => void) => {
  const htmlTargetElement = eventTargetIsHTMLElement(event.target) ? event.target : null;

  if (!htmlTargetElement || !event.target) return null;

  if (htmlTargetElement.nodeName !== 'STOE-MINICART') {
    callback();
  }
};

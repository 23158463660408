import { createRoot, Root } from 'react-dom/client';
import MiniCart, { type MiniCartProps, stylesList } from './minicart';

class StandaloneComponent extends HTMLElement {
  mountPoint!: HTMLSpanElement;
  private minicartProps: Partial<MiniCartProps> = {};
  private root!: Root;

  connectedCallback() {
    const mountPoint = document.createElement('span');
    const shadowRoot = this.attachShadow({ mode: 'open' });

    for (const styleInfo of stylesList) {
      const styleElement = document.createElement('style');
      styleElement.textContent = styleInfo.style;
      shadowRoot.appendChild(styleElement);
    }
    shadowRoot.appendChild(mountPoint);

    // Populate Props from attributes
    const attributeNames = this.getAttributeNames();
    attributeNames.forEach((attrName) => {
      const value = this.getAttribute(attrName);
      if (value !== null) {
        this.minicartProps[attrName as keyof MiniCartProps] = value;
      }
    });

    this.root = createRoot(mountPoint);
    this.renderComponent();
  }

  static get observedAttributes() {
    return ['token', 'store', 'locale', 'i18n']; // Add other attributes here if needed
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
    if (oldValue !== newValue) {
      //@ts-expect-error this line throws an error if no props are in the component, but the code will still compile, since attributeNames is empty and will not map
      this.minicartProps[name as keyof MiniCartProps] = newValue;
      this.renderComponent();
    }
  }

  private renderComponent() {
    if (this.root) {
      //eslint-disable-next-line
      //@ts-ignore
      this.root.render(<MiniCart {...this.minicartProps} />);
    }
  }
}

export default StandaloneComponent;
// the name of the custom Element can be edited here:
window.customElements.get('stoe-minicart') ||
  window.customElements.define('stoe-minicart', StandaloneComponent);

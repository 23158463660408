import { I18nTranslations } from './minicart.types';

const SAP_COMMERCE_URL = import.meta.env.PROD
  ? 'https://api.cu91cv9jmr-stoberant1-p1-public.model-t.cc.commerce.ondemand.com/occ/v2'
  : 'https://api.cu91cv9jmr-stoberant1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2';

const CART_URL_SDI = import.meta.env.PROD
  ? 'https://shop.stober.com/en-US/cart'
  : 'https://stage-shop.stober.com/en-US/cart';
const CART_URL_SAT = import.meta.env.PROD
  ? 'https://shop.stoeber.de/de-DE/cart'
  : 'https://stage-shop.stoeber.de/de-DE/cart';

const i18nTranslationsDefault: I18nTranslations = {
  'de-DE': {
    trigger: { label: 'Warenkorb', count: 'Anzahl' },
    content: {
      removeProduct: 'Produkt aus dem Warenkorb entfernen',
      toCart: 'Zum Warenkorb',
      loading: 'loading',
      unitPrice: 'Einzelpreis',
      close: 'Schließen',
    },
  },
  'en-US': {
    trigger: { label: 'cart', count: 'quantity' },
    content: {
      removeProduct: 'remove product from cart',
      toCart: 'View Cart',
      loading: 'loading',
      unitPrice: 'unit price',
      close: 'close',
    },
  },
};

export { SAP_COMMERCE_URL, CART_URL_SDI, CART_URL_SAT, i18nTranslationsDefault };
